/***
*
*   FEATURES
*   Feature list for use on home/landing pages
*
**********/

import React from 'react';
import { Grid, Link } from 'components/lib';
import Style from './features.module.scss';

import JSONPretty from 'react-json-prettify';
import { github } from 'react-json-prettify/dist/themes';

export function Features (props) {
  const sampleData = {
    domain: {
      domain: 'bhhscalreal.com',
      email_pattern: 'flast',
      location: {
        country: 'United States',
        city: 'Houston'
      },
      emails: [
        {
          email: 'bboisson@bhhscalreal.com',
          personal: true,
          verified: false,
          verify: '/v1/email/verify?email=bboisson@bhhscalreal.com',
          domain: 'bhhscalreal.com',
          twitter: null,
          linkedin: null,
          name: {
            confidence: 0.8,
            name: 'Brian Boisson',
            pattern: 'flast'
          },
          references: [
            {
              url: 'http://www.homeseekers.com/homes-for-sale/603-Natoma-Street-UNIT-408-San-Francisco-CA-94103-168254163',
              timestamp: '2017-04-28T14:22:10Z'
            }
          ],
          location: null,
          job_title: null,
          numbers: []
        }
      ]
    },
    email: {
      email: 'professional.learning@eastkent.ac.uk',
      personal: false,
      verified: false,
      verify: '/v1/email/verify?email=professional.learning@eastkent.ac.uk',
      domain: 'eastkent.ac.uk',
      linkedin: null,
      twitter: {
        twitter: 'EKCProfessional',
        confidence: 0.75
      },
      name: {
        confidence: 1,
        name: 'Professional Learning',
        pattern: 'first.last'
      },
      references: [
        {
          url: 'https://www.eventbrite.co.uk/e/2-day-project-management-techniques-canterbury-tickets-25314652808',
          timestamp: '2017-03-22T23:07:15Z'
        }
      ],
      job_title: {
        confidence: 0.66,
        job_title: 'Organiser'
      },
      numbers: [
        '+441304244442'
      ]
    },
    verification: {
      mx_set: true,
      mx_records: [
        {
          priority: 1,
          address: 'aspmx.l.google.com'
        },
        {
          priority: 5,
          address: 'alt1.aspmx.l.google.com'
        },
        {
          priority: 5,
          address: 'alt2.aspmx.l.google.com'
        },
        {
          priority: 10,
          address: 'mxb.mailgun.org'
        },
        {
          priority: 10,
          address: 'mxa.mailgun.org'
        },
        {
          priority: 10,
          address: 'alt4.aspmx.l.google.com'
        },
        {
          priority: 10,
          address: 'alt3.aspmx.l.google.com'
        }
      ],
      valid_mx: true,
      email_sendable: true,
      accepts_all_emails: false
    }
  };

  return (
    <div className={Style.features}>
      <Grid cols={2}>

        <div>
          <h1>Domain Lookup</h1>
          <br />
          <p>Look up a domain name and get back information such as email addresses, people, job titles, phone numbers, addresses and more…</p>

          <div className={Style.requestExample}>
            <div className={Style.httpType}>
              GET
            </div>
            <div className={Style.params}>
              {'{ domain: "bhhscalreal.com" }'}
            </div>
          </div>

          <Link url='https://emailcrawlr.com/documentation/' title='Manage your billing' text='See Full API Docs' />
        </div>

        <div>
          <section className={Style.code}>
            <JSONPretty json={sampleData.domain} theme={github} padding={3} />
          </section>

        </div>

      </Grid>
      <br /><br /><br /><br />
      <Grid cols={2}>

        <div>
          <h1>Email Lookup</h1>
          <br />
          <p>Look up a domain name and get back information such as email addresses, people, job titles, phone numbers, addresses and more…</p>

          <div className={Style.requestExample}>
            <div className={Style.httpType}>
              GET
            </div>
            <div className={Style.params}>
              {'{ email: "professional.learning@eastkent.ac.uk" }'}
            </div>
          </div>

          <br />
          <center>
            <h3>OR</h3>
          </center>
          <br />

          <div className={Style.requestExample}>
            <div className={Style.httpType}>
              GET
            </div>
            <div className={Style.params}>
              {'{ domain: "eastkent.ac.uk", full_name: "Full Name" }'}
            </div>
          </div>

          <Link url='https://emailcrawlr.com/documentation/' title='Manage your billing' text='See Full API Docs' />
        </div>

        <div>
          <section className={Style.code}>
            <JSONPretty json={sampleData.email} theme={github} padding={3} />
          </section>

        </div>

      </Grid>

      <br /><br /><br /><br />

      <Grid cols={2}>

        <div>
          <h1>Email Verification</h1>
          <br />
          <p>Check/Validate an email address's deliverability. Checks MX records as well as email receipt.</p>

          <div className={Style.requestExample}>
            <div className={Style.httpType}>
              GET
            </div>
            <div className={Style.params}>
              {'{ email: "hello@emailcrawlr.com" }'}
            </div>
          </div>

          <Link url='https://emailcrawlr.com/documentation/' title='Manage your billing' text='See Full API Docs' />
        </div>

        <div>
          <section className={Style.code}>
            <JSONPretty json={sampleData.verification} theme={github} padding={3} />
          </section>

        </div>

      </Grid>
    </div>
  );
}
