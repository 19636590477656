import React, { Fragment, useState, useEffect } from 'react';
import { Button, Card, useAPI } from 'components/lib';
import DataTable from 'react-data-table-component';

export function ExportSearch (props) {
  const [items, setItems] = useState([]);
  const data = useAPI('/api/savedEmails');

  // datatable columns
  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true
    },
    {
      name: 'Total',
      selector: 'num_count',
      sortable: true
    },
    {
      name: 'User',
      selector: 'user_name',
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      cell: (row) => <Button
        icon='download'
        text='Export'
        action={() => {
          downloadCSV(row.emails);
        }}
                     />
    }
  ];

  function convertArrayOfObjectsToCSV (array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV (array) {
    const toExport = array.map(item => {
      return { ...item, email: item.email.join('|') };
    });

    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(toExport);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  // if (!data) { return false; }
  useEffect(() => {
    if (data?.data?.length) {
      const ret = data.data.map(item => {
        return { ...item, num_count: item.emails.length, user_name: item.user[0].name };
      });

      setItems(ret);
    }
  }, [data.data]);
  console.log(items);
  return (
    <>

      <center>
        <h1>Export Your Searches</h1> <br />
        <h4>Download your Anymail finder data in a .csv file you can import wherever you like.</h4>
      </center>

      {/* render view data */}
      <br />
      <Card loading={data.loading}>

        <DataTable
          title='Saved Searches'
          columns={columns}
          data={items}
        />
      </Card>

    </>

  );
}
