/***
*
*   SECURITY
*   User can update their password and enable 2fa
*
**********/

import React, { Fragment } from 'react';
import { Password } from './password';
import { TwoFA } from './2fa';
import { AccountNav, TabView } from 'components/lib';

export function Security(props){

  return (
    <Fragment>

      <AccountNav />
     
      <TabView name='Security' labels={['Password', 'Two-Factor Authentication']}>
        <Password /> 
        <TwoFA />
      </TabView>

    </Fragment>
  )
}