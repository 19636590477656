/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import { Link } from 'components/lib';
import LogoEmail from './images/email_crawler_logo.png';
import LogoMark from './images/logo-mark.svg';
import Style from './logo.module.scss';

export function Logo (props) {
  const css = ClassNames([

    Style.logo,
    props.className

  ]);

  return (
    <Link url='/' className={css}>
      <img src={props.mark ? LogoMark : LogoEmail} alt='Logo' />
    </Link>
  );
}
