/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import React, { useState } from 'react';
import { Animate, Content, Link, Card, Button, TextInline } from 'components/lib';
import Style from './hero.module.scss';
import JSONPretty from 'react-json-prettify';
import { darcula } from 'react-json-prettify/dist/themes';
import Axios from 'axios';

export function Hero (props) {
  const sampleData = {
    domain: 'bhhscalreal.com',
    email_pattern: 'flast',
    location: {
      country: 'United States',
      city: 'Houston'
    },
    emails: [
      {
        email: 'bboisson@bhhscalreal.com',
        personal: true,
        verified: false,
        verify: '/v1/email/verify?email=bboisson@bhhscalreal.com',
        domain: 'bhhscalreal.com',
        twitter: null,
        linkedin: null,
        name: {
          confidence: 0.8,
          name: 'Brian Boisson',
          pattern: 'flast'
        },
        references: [
          {
            url: 'http://www.homeseekers.com/homes-for-sale/603-Natoma-Street-UNIT-408-San-Francisco-CA-94103-168254163',
            timestamp: '2017-04-28T14:22:10Z'
          }
        ],
        location: null,
        job_title: null,
        numbers: []
      }
    ]
  };

  const PrettyPrintJSON = (data) => {
    return (
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };

  const [textValue, setTextValue] = useState('producthunt.com');
  const [searchData, setSearchData] = useState(sampleData);

  const lookup = async () => {
    const res = await Axios.get('/api/domain_test', { params: { domain: textValue } });
    console.log(res);
    setSearchData(res.data);
    console.log(searchData);
  };

  const onChange = (a, b) => {
    setTextValue(b);
  };

  return (
    <section className={Style.hero}>
      <Content>

        <Animate type='pop'>
          <section className={Style.blurb}>
            <h1>{props.title}</h1>
            <h2>{props.tagline}</h2>
            <Link btn big url='/signup' text='Sign Up For Free' /> &nbsp;&nbsp;
            <Link btn big url='/pricing' text='Pricing' />
          </section>
        </Animate>

        <Animate>
          {/* <Image className={Style.visual} source={props.image} alt={props.alt} /> */}
          <div className={Style.term}>
            <section>
              <Card>
                <form className={Style.formInline} action='/action_page.php'>
                  <TextInline onChange={onChange} value={textValue} />
                  {/* <input type='text' id='text' placeholder='Enter email' name='email' /> */}
                  <Button
                    small
                    outline
                    rounder
                    text='Lookup'
                    action={lookup}
                  />
                </form>
              </Card>
            </section>
            <section className={Style.code}>
              <JSONPretty json={searchData} theme={darcula} padding={3} />
            </section>
          </div>
        </Animate>

      </Content>
    </section>
  );
}
