/***
*
*   HOME LAYOUT
*   Main website layout
*
**********/

import React, { Fragment } from 'react';
import { HomeNav, Footer } from 'components/lib';

export function HomeLayout (props) {
  const transparent = props?.data?.location?.pathname === '/';

  return (
    <>
      <main className='home'>

        <HomeNav transparent={ transparent } />
        { <props.children {...props.data }/> }

      </main>
      <Footer />
    </>
  );
}
