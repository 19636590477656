import React, { useState, useContext } from 'react';
import { useAPI, Form, Button, Card, ViewContext, Select, Message, TextInput } from 'components/lib';
import DataTable from 'react-data-table-component';
import Style from './emailSearch.module.scss';

export function EmailSearch(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTotal, setSearchTotal] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterCityData, setfilterCityData] = useState([]);
  const [filterIndustryData, setfilterIndustryData] = useState([]);

  const [hasEmail, setHasEmail] = useState(false);
  const [hasWebsite, setHasWebsite] = useState(false);
  const [filterCity, setFilterCity] = useState('unselected');
  const [filterIndustry, setFilterIndustry] = useState('unselected');
  const context = useContext(ViewContext);
  // Paging is expensive, cache prev results
  const [perPage, setPerPage] = useState(50); // Note that default setPerPage should be the same in the size default backend
  const [nextCursor, setNextCursor] = useState('');
  const [cursorLookup, setCursorLookup] = useState({ 1: 'initial' });
  const [updatedCredits, setUpdatedCredits] = useState(false);
  const [newCredits, setNewCredits] = useState('');

  const data = useAPI('/api/emailSearch');
  const dataApiCalls = useAPI('/api/apiKey/totalMonthCalls');

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: 'Name',
      selector: 'fullname',
      sortable: true
    },
    {
      name: 'Company',
      selector: 'job_company_name',
      sortable: true
    },
    {
      name: 'email',
      selector: 'email',
      sortable: true
    },
    {
      name: 'Website',
      button: true,
      cell: row => (
        <a href={'http://www.' + row.job_company_website} target='_blank' rel="noreferrer">
          {row.job_company_website}
        </a>
      )

    },
    {
      name: 'Verified',
      selector: 'verified',
      sortable: true
    }
  ];

  const filterData = async () => {
    let retCity;
    let retIndustry;
    let retEmail;
    let retWebsite;

    if (filterCity !== 'unselected') {
      retCity = searchData.filter(e => {
        let city = false;
        if (e.location_region) {
          const b = e.location_region.filter(item => item.toLowerCase().indexOf(filterCity) > -1);
          city = (b.length > 0);
        } else {
          city = false;
        }

        return city;
      });
    } else {
      retCity = searchData;
    }

    if (filterIndustry !== 'unselected') {
      retIndustry = retCity.filter(e => {
        let industry = false;
        if (e.industry) {
          const b = e.industry.filter(item => item.toLowerCase().indexOf(filterIndustry) > -1);
          industry = (b.length > 0);
        } else {
          industry = false;
        }

        return industry;
      });
    } else {
      retIndustry = retCity;
    }

    if (hasEmail) {
      retEmail = retIndustry.filter(e => {
        return e.email !== '';
      });
    } else {
      retEmail = retIndustry;
    }
    // setFilteredData(retEmail);

    if (hasWebsite) {
      retWebsite = retEmail.filter(e => {
        return (e.job_company_website) && (e.job_company_website.length > 0);
      });
    } else {
      retWebsite = retEmail;
    }
    setFilteredData(retWebsite);
  };

  const handleSelectedChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    setSelectedRows(state.selectedRows);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    // Remove the ids
    keys.shift();
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const saveEmails = () => {
    context.modal.show({
      title: 'Save Selected Data',
      form: {
        name: {
          type: 'text',
          label: 'Name'
        },
        description: {
          type: 'text',
          label: 'Short Description'
        },
        emails: {
          type: 'hidden',
          value: selectedRows
        }
      },
      buttonText: 'Save Data',
      url: '/api/savedEmails',
      method: 'POST'

    }, (data) => {
      context.notification.show('Data Saved', 'success', true);
    });
  };

  const processFilterCity = (input, value, valid) => {
    setFilterCity(value);
  };

  const processFilterIndustry = (input, value, valid) => {
    setFilterIndustry(value);
  };

  const processFilterDropdowns = (items) => {
    const x = [];
    const z = [];
    items.forEach(e => {
      if (e.location_region) {
        if (x.some(u => u.value === e.location_region[0]) === false) {
          x.push({ label: e.location_region[0], value: e.location_region[0] });
        }
      }

      if (e.industry) {
        if (z.some(u => u.value === e.industry[0]) === false) {
          z.push({ label: e.industry[0], value: e.industry[0] });
        }
      }
    });

    setfilterCityData(x);
    setfilterIndustryData(z);
  };

  if (!data) { return false; }
  return (
    <>
      {/* render view data */}
      <center>
        <h1>Search Email Address</h1>
      </center>

      <Card>
      <Message
        title='Remaining credits'
        closable={false}>
        <TextInput value={updatedCredits ? newCredits : (dataApiCalls?.data?.remainingCredits || '0')} />
      </Message>
        <Form
          data={{
            textSearch: {
              label: 'Person Name or Job Title, Website or Company Name',
              type: 'text',
              required: true,
              errorMessage: 'Please enter Person Name or Job Title'
            },
            creditsToUse: {
              label: 'Use Credits',
              type: 'number',
              min: 1,
              max: 10000,
              value: 100
             }
          }}
          method='POST'
          url='/api/searchEmails'
          buttonText='Search'
          callback={(response) => {
            if (response.data.data) {
              const {
                data: {
                  data: {
                    items, nextCursor, term, totalFound, remainingCredits
                  }
                }
              } = response;

              setSearchData(items);
              setFilteredData(items);
              setSearchTerm(term);
              setSearchTotal(totalFound);
              setNextCursor(nextCursor);
              processFilterDropdowns(items);
              setUpdatedCredits(true);
              setNewCredits(remainingCredits?.toString());
            }
          }}
        />
        <br />
      </Card>

      {
        (searchTerm === '')
          ? null
          : (
            <div>
              <section>
                <section style={{ position: 'relative', padding: '1.5em', width: '29%', float: 'left' }}>
                  <Card>
                    <h3>Filters</h3>
                    <br />
                    <br />

                    <p>
                      Has Emails :  &nbsp; &nbsp;   <input type='checkbox' onChange={(e) => setHasEmail(e.target.checked)} />
                    </p>

                    <p>
                      Has Website :  &nbsp; &nbsp;   <input type='checkbox' onChange={(e) => setHasWebsite(e.target.checked)} />
                    </p>

                    <p>
                      City :  &nbsp; &nbsp;   <Select
                        options={filterCityData} onChange={processFilterCity} label='Select City'
                      />
                    </p>

                    <p>
                      Industry :  &nbsp; &nbsp;   <Select
                        options={filterIndustryData} onChange={processFilterIndustry} label='Select Industry'
                      />
                    </p>

                    <Button
                      className={Style.emailSearch}
                      small
                      fullWidth
                      outline
                      rounder
                      text='Filter Data'
                      action={filterData}
                    />

                  </Card>
                </section>
                <section style={{ position: 'relative', padding: '1.5em', width: '71%', float: 'left' }}>
                  <Card>

                    <div style={{ textAlign: 'right' }}>
                      <Button
                        className={Style.emailSearch}
                        small
                        outline
                        rounder
                        icon='save'
                        text='Save Selected Data'
                        action={saveEmails}
                      />
                      &nbsp;
                      <Button
                        small
                        outline
                        rounder
                        icon='download'
                        text='Export Selected Data'
                        action={() => {
                          downloadCSV(selectedRows);
                        }}
                      />

                    </div>

                    <br />
                    <DataTable
                      title='Search Results'
                      columns={columns}
                      data={filteredData}
                      selectableRows
                      progressPending={loading}
                      onSelectedRowsChange={handleSelectedChange}
                      pagination
                      paginationPerPage={15}
                      paginationComponentOptions={{
                        noRowsPerPage: true
                      }}
                    />
                  </Card>
                </section>
              </section>
            </div>
          )
      }
    </>
  );
}
