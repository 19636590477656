/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';
import { Animate, Row, Content, Link } from 'components/lib';
import Style from './footer.module.scss';

export function Footer (props) {
  return (
    <Animate type='slideup'>
      <footer className={Style.footer}>
        <Row>
          <Content>

            <nav>
              <Link url='/' text='Home' />
              <Link url='/documentation' text='Documentation' />
              <Link url='/pricing' text='Pricing' />
              <Link url='/signin' text='Sign in' />
              <Link url='/signup' text='Sign up' />
              <Link url='/terms' text='Terms' />
              <Link url='/privacy' text='Privacy' />
              <Link url='/contact' text='Contact' />
            </nav>

            <span>{`Copyright © EmailCrawlr ${new Date().getFullYear()}`}</span>
            <address>EmailCrawlr</address>

          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
