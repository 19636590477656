import React, { Fragment, useState, useEffect } from 'react';
import { Card, Form, Message, useAPI } from 'components/lib';

export function TwoFA(props){

  // get the user state 
  const user = useAPI('/api/user');
  const [qrCode, setQrCode] = useState(undefined);
  const [enabled, setEnabled] = useState(undefined);

  useEffect(() => {

    setEnabled(user.data?.['2fa_enabled']);

  }, [user.data])

  return (
    <Card title='Two-Factor Authentication' restrictWidth className={ props.className } loading={ user.loading }>
      
      <Form 
        url='/api/user/2fa'
        method='put'
        submitOnChange
        data={{
          '2fa_enabled': {
            type: 'switch',
            label: 'Enable two-factor authentication',
            default: user?.data?.['2fa_enabled']
          }
        }}
        callback={ res => {
          
          setQrCode(res.data.data.qr_code);
          setEnabled(res.data.data['2fa_enabled'])
        
        }}
      />

      { qrCode ? 
        <Message 
          title='Scan QR Code'
          type='info'
          text='Please scan the QR code below with an authenticator app such as Google Authenticator or Authy.'>

            <img src={ qrCode } alt='Please scan this with your authenticator app' />

        </Message> : 
        <Fragment>
          { enabled ?
          <Message 
            title='2FA Enabled'
            type='success'
            text='Good job! Two-factor authentication is enabled on your account.'
          /> :
          <Message
            title='Enable 2FA'
            type='warning'
            text={ `We recommended enabling 2FA on your account for added security.` }
          />
          }
        </Fragment>
      }
    </Card>
  )
}