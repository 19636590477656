import React from 'react';
import { Card, Message, TextInput, useAPI } from 'components/lib';

export function APIDashboard(props) {

  const data = useAPI('/api/apiKey/get');
  const dataCalls = useAPI('/api/apiKey/totalMonthCalls');

  return (
    <Card>
      <Message
        title='Total calls this month'
        type='success'
        closable={false}>
        <TextInput value={dataCalls?.data?.totalCalls || '0'} />
      </Message>
      <Message
        title='Remaining credits'
        type='success'
        closable={false}>
        <TextInput value={dataCalls?.data?.remainingCredits || '0'} />
      </Message>
      <Message
        title='Your API Key'
        type='warning'
        text='Your API key is below. Please store this somewhere safe.'
        closable={false}>
        <TextInput value={data?.data?.key} />
      </Message>
      <Message
        title='Documentation'
        type='info'
        closable={false}>
        Read the documentation on how to use the API.<br/><a href='/documentation' target={'_blank'}>Click here</a>
      </Message>
    </Card>
  )
};
