import { ExportSearch } from 'views/exportSearch';
import { EmailSearch } from 'views/emailSearch/emailSearch';
import { Help } from 'views/dashboard/help';
import { APIDashboard } from 'views/api/dashboard';

const Routes = [
  {
    path: '/dashboard',
    view: EmailSearch,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/emailSearch',
    view: EmailSearch,
    layout: 'app',
    permission: 'user',
    title: 'EmailSearch'
  },
  {
    path: '/exportSearch',
    view: ExportSearch,
    layout: 'app',
    permission: 'user',
    title: 'ExportSearch'
  },
  {
    path: '/apiDashboard',
    view: APIDashboard,
    layout: 'app',
    permission: 'user',
    title: 'API Calls'
  }
];

export default Routes;
