/***
*
*   SIGN UP STEP 2
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext } from 'react';
import { Animate, AuthContext, Row, Card, PaymentForm, usePlans, Link, History, Event } from 'components/lib';

export function SignupPlan (props) {
  const context = useContext(AuthContext);
  const plans = usePlans();
  const plan = window.location.hash.substring(1);

  if (!plans.data)
    {return false;}

  return (
    <Animate type='pop'>
      <Row title='Select Your Plan'>
        <Card loading={false} restrictWidth center>

          <PaymentForm
            data={{
              plan: {
                label: 'Plan',
                type: 'select',
                options: plans.data.list,
                default: plan,
                required: true
              },
              token: {
                label: 'Credit Card',
                type: 'creditcard',
                required: true
              }
            }}
            url='/api/account/plan'
            method='POST'
            buttonText='Save Plan'
            callback={res => {
              // save the plan to context, then redirect
              Event('selected_plan', { plan: res.data.plan });
              context.update({ plan: res.data.plan, subscription: res.data.subscription });
              History.push('/dashboard');
            }}
          />

          <Link url='/account' text='Manage Your Account' />

        </Card>
      </Row>
    </Animate>
  );
}
