/***
*
*   FEATURES
*   Feature list for use on home/landing pages
*
**********/

import React from 'react';
import { Grid, Icon } from 'components/lib';
import Style from './faq.module.scss';

export function Faq (props) {
  return (
    <div className={Style.features}>
      <Grid cols={2}>

        <div>
          <h3>What happens if I exceed my query allowance?</h3>
          <br />
          <p>Not a problem! Your queries will still go through. We will send you an email and gracefully upgrade your acccount for the next month.</p>
        </div>

        <div>
          <h3>What happens if you don’t have a domain?</h3>
          <br />
          <p>Despite our best effort, sometimes contact information just isn’t available for some domains. We will simply tell you to try the domain again later and you won't be charged for the call.</p>
        </div>

        <div>
          <h3>Can I cancel my account?</h3>
          <br />
          <p>Yes, you can cancel at any time! Hopefully you and EmailCrawlr will still be friends.</p>
        </div>

        <div>
          <h3>Can you handle large query volume?</h3>
          <br />
          <p>Yes, however large your volume we can handle it. Have a specific conccurency requirement? </p>
        </div>

      </Grid>
    </div>
  );
}
