/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { useState, useContext, Fragment } from 'react';
import { Row, Card, Link, Hero, Features, Faq } from 'components/lib';
import ImgDashboard from './images/dashboard.png';
import Slider, { createSliderWithTooltip } from 'rc-slider';

export function Home (props) {
  const [value, setValue] = useState(50);

  const onSliderChange = value => {
    setValue(value);
  };

  const onAfterChange = value => {
    console.log(value); //eslint-disable-line
  };

  return (
    <>

      <Hero
        title='By using the EmailCrawlr JSON API you can:'
        tagline='Get key information about company websites.
        Find all email addresses associated with a domain.
        Get social accounts associated with an email.
        Verify email address deliverability.'
        image={ImgDashboard}
      />

      <Row title='' color='white'>
        <Features />
      </Row>

      <Row title='Pricing Calculator' color='brand'>
        <Card>
          <div style={{ width: '100%' }}>
            <Slider
              value={value}
              onChange={onSliderChange}
              onAfterChange={onAfterChange}
            />
          </div>
        </Card>
      </Row>

      {/* <Row color='brand'>
        <Testimonial
          text='Gravity saved me weeks of development time and thousands of dollars by eliminating the need to hire a developer to built the necessary infrastructure for my app. I had a beautiful product with billing capabilities in a matter of minutes!'
          author='Kyle Gawley, Gravity Founder'
          image={ImgKyleGawley}
        />
      </Row> */}

      <Row title='FAQ' align='center'>
        <Faq />
      </Row>
    </>
  );
}
