/***
*
*   PRICE PLANS
*   Pricing table for your landing/pricing page
*   Update the features array in /config file with your own
*
**********/

import React from 'react';
import { Card, CheckList, Button, History, usePlans } from 'components/lib';
import Style from './plans.module.scss';

export function PricePlans (props) {
  const plans = usePlans();

  if (!plans.data) { return false; }

  return (
    <section className={Style.plans}>
      {plans?.data?.raw?.plans.map((plan, index) => {
        return (
          <Card
            shadow rounded
            key={plan.id}
            className={Style.plan}
          >

            <div className={Style.name}>
              {plan.name}
            </div>

            <div className={Style.price}>
              {plan?.currency?.symbol}{plan.price}
              <small>{plan.interval && '/'}{plan.interval}</small>
            </div>

            <CheckList items={plan.features} />
            <Button text='Sign Up' action={e => History.push('/signup')} />

          </Card>
        );
      })}
    </section>
  );
}
