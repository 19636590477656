import { Profile } from 'views/account/profile';
import { Billing } from 'views/account/billing';
import { Upgrade } from 'views/account/upgrade';
import { Users } from 'views/account/users';
import { Security } from 'views/account/security/index';

const Routes = [
  {
    path: '/account',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'Your Profile'
  },
  {
    path: '/account/security',
    view: Security,
    layout: 'app',
    permission: 'user',
    title: 'Account Security'
  },
  {
    path: '/account/billing',
    view: Billing,
    layout: 'app',
    permission: 'owner',
    title: 'Billing'
  },
  {
    path: '/account/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'user',
    title: 'Upgrade'
  },
  {
    path: '/account/users',
    view: Users,
    layout: 'app',
    permission: 'admin',
    title: 'Users'
  }
]

export default Routes;
